<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'Profile',
  components: {
    Icon: () => import('@/components/general/Icon'),
    Loading: () => import('@/components/general/Loading'),
    Upload: () => import('@/components/general/Upload'),
    SelectField: () => import('@/components/general/SelectField')
  },
  props: {
    classes: String
  },
  data () {
    return {
      formData: {
        image: null
      },
      isOpen: false,
      profilesOptions: [],
      profileSelect: null,
      changingImage: false,
      loading: false
    }
  },
  computed: {
    ...mapState(['Account']),
    name () {
      return this.Account.user.data && this.Account.user.data.name ? this.Account.user.data.name : ''
    },
    profileImage () {
      return this.Account.user.profile_image
    }
  },
  watch: {
    profileImage () {
      this.formData.image = this.profileImage
    }
  },
  methods: {
    ...mapActions([
      'attemptSaveUserPhoto',
      'attemptChangeUserProfile',
      'attemptUserAccountRetrieval',
      'setFetching'
    ]),
    toggleMenu () {
      this.isOpen = !this.isOpen
    },
    openModal () {
      this.changingImage = true
    },
    closeMenu () {
      if (!this.changingImage) {
        this.isOpen = false
      }
    },
    changeImage (image) {
      this.changingImage = false
      this.loading = true
      this.attemptSaveUserPhoto(image).finally(() => {
        this.loading = false
      })
    },
    changeProfile (event) {
      const profile = this.Account.user.profiles.filter((item) => {
        return item.id === event
      })
      const data = {
        uuid: this.Account.user.uuid,
        profile_id: profile[0].id,
        profile: profile[0].alias
      }
      if (this.Account.user.currentProfileId !== profile[0].id) {
        this.setFetching(true)
        this.attemptChangeUserProfile(data).then(() => {
          this.attemptUserAccountRetrieval().then(() => {
            this.setFetching(false)
            this.$router.push({ name: 'index' })
          })
        })
      }
    }
  },
  mounted () {
    this.formData.image = this.profileImage
    this.profileSelect = this.Account.user.currentProfileId
    this.profilesOptions = this.Account.user.profiles.map(item => {
      return {
        value: item.id,
        text: item.alias === 'agent' ? item.name : this.$t(`global:${item.alias}`)
      }
    })
  }
}
</script>

<template>
  <div
    v-click-outside="closeMenu"
    class="profile"
    :class="{ 'is-open': isOpen }"
  >
    <a
      href="#"
      class="profile-link"
      :class="[classes]"
      @click.prevent="toggleMenu"
    >
      <img
        v-if="profileImage"
        :src="profileImage"
        :alt="name"
        class="profile-link-image"
      >
      <icon
        v-else
        name="account_circle"
        class="profile-link-icon"
      />
    </a>
    <transition :name="$media.mobile ? 'slideLeft' : 'fadeInUp'">
      <div
        v-if="isOpen"
        class="profile-content"
      >
        <div class="profile-content-info">
          <div
            class="profile-content-image"
            :class="{ 'is-loading': loading }"
          >
            <loading
              v-if="loading"
              dark
            />
            <upload
              ref="upload"
              v-model="formData.image"
              :width="90"
              :height="90"
              :cropper="true"
              icon="add_a_photo"
              @input="changeImage"
              @open="openModal"
            >
              <icon
                slot="image"
                name="account_circle"
                class="profile-content-image-icon"
              />
              <template slot="change">
                <div class="uploader-label-change-container">
                  <icon
                    slot="change"
                    name="add_a_photo"
                    class="uploader-label-change-container-icon"
                  />
                </div>
              </template>
            </upload>
          </div>
          <p
            class="profile-content-name"
            v-html="$t('global:profile.greetings', {'name': name})"
          />
          <div
            v-if="profilesOptions.length > 1"
            class="profile-content-select"
          >
            <icon
              name="account-convert"
              wrapper
            />
            <select-field
              v-model="profileSelect"
              :label="$t('global:profile.change.profile.label')"
              :items="profilesOptions"
              @input="changeProfile($event, $data)"
            />
          </div>
        </div>
        <div class="profile-content-list">
          <router-link
            :to="'/profile'"
            class="profile-content-link"
          >
            <icon
              name="person"
              wrapper
            />
            <span class="text">{{ $t('global:profile.menu.1') }}</span>
          </router-link>
          <!-- <router-link :to="'/'" class="profile-content-link">
            <icon name="settings" wrapper></icon>
            <span class="text">{{ $t('global:profile.menu.2') }}</span>
          </router-link>
          <router-link :to="'/'" class="profile-content-link">
            <icon name="shopping_basket" wrapper></icon>
            <span class="text">{{ $t('global:profile.menu.3') }}</span>
          </router-link> -->
          <router-link
            :to="{ name: 'auth.logout' }"
            class="profile-content-link"
          >
            <icon
              name="logout"
              wrapper
            />
            <span class="text">{{ $t('global:profile.menu.4') }}</span>
          </router-link>
        </div>
        <a
          href="#"
          class="profile-close"
          @click.prevent="toggleMenu"
        >
          <icon
            name="close"
            wrapper
          />
        </a>
      </div>
    </transition>
  </div>
</template>

<style src="@/assets/styles/themes/default/profile.css"></style>
